import React, { Component } from 'react'
import VideoPlayer from "../components/video-player/videoPlayer";
import {Container} from "react-bootstrap";
import NavbarInContext from "../components/navBarInContext";
import videosLibrary from "../services/videos";
import CustomNavbar from "../components/navBar";
import UnauthenticatedFullscreenLayout from "../components/layouts/unauthenticatedFullscreenLayout";
import Step from "../components/course-browser/step";
import newCourseService from "../services/course_service";
const containerStyle = {
    width: "100vw",
    height: "56.25vw",
    maxHeight: "100vh",
    maxWidth: "177.78vh",
    margin: "auto",
    position: "absolute",
    top: "0",
    bottom: "0",
    left: "0",
    right: "0"
};

const PreviewPage = () => (
    <UnauthenticatedFullscreenLayout title={"Preview"} section={newCourseService.sections.preview}>
        <Step />
    </UnauthenticatedFullscreenLayout>
)

export default PreviewPage